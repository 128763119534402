import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useMediaQuery } from '../hooks/useMediaQuery'
import { LANG_LIST } from '../utils/constants'

type AppContextType = {
  lang: string
  media: string
  location: any
  contactElm: number
  isScrolled: boolean
  handleContactElm: (contactElm: any) => void
  handleLang: (lang: string) => void
  handleScrollFlg: (isScrolled: boolean) => void
}
export const AppContext = React.createContext({} as AppContextType)

export const AppProvider = ({ children }) => {
  const params = useParams()
  let pageLang = 'en'

  if (params.lang !== undefined) {
    const initialLang = params.lang !== undefined ? params.lang : 'en'
    pageLang = initialLang
    if (LANG_LIST.indexOf(initialLang) === -1) {
      pageLang = 'en'
    }
  } else {
    console.log('languages>>', navigator.languages)
    if (navigator.languages !== undefined) {
      let mainBrowserLang = navigator.languages[0]
      let trimmedLocale = mainBrowserLang.split(/-|_/)[0]
      pageLang = trimmedLocale
      console.log('langCode>>', trimmedLocale)
      //lang code used in the the code is 'jp'. So, redirect ..
      if (trimmedLocale === 'ja') pageLang = 'jp'
    } else {
      console.log('navigator lang NOT FOUND..')
      pageLang = 'en'
    }

    if (LANG_LIST.indexOf(pageLang) === -1) {
      pageLang = 'en'
    }
  }

  // let pageLang = 'en';

  const [lang, setLang] = useState(pageLang)
  const [isScrolled, setIsScrolled] = useState(false)
  const [contactElm, setContactElm] = useState(null)
  const { isPC, isSP, isTB } = useMediaQuery()
  const location = useLocation()

  const handleLang = (lang: string) => {
    setLang(lang)
  }
  const handleContactElm = (top) => {
    setContactElm(top)
  }
  const handleScrollFlg = (isScrolled) => {
    setIsScrolled(isScrolled)
  }

  let media = 'dt'
  if (isSP) {
    media = 'sp'
  } else if (isTB) {
    media = 'tb'
  } else if (isPC) {
    media = 'pc'
  }

  // useEffect(() => {
  //   console.log("languages>>",navigator.languages)
  //   if(navigator.languages !== undefined){
  //     let mainBrowserLang = navigator.languages[0]
  //     let trimmedLocale = mainBrowserLang.split(/-|_/)[0]
  //     pageLang = trimmedLocale;
  //     console.log("langCode>>",trimmedLocale)
  //     //lang code used in the the code is 'jp'. So, redirect ..
  //     if(trimmedLocale === 'ja')
  //       pageLang = 'jp'
  //   }else{
  //     pageLang = 'en'
  //   }

  //   if (LANG_LIST.indexOf(pageLang) === -1) {
  //     pageLang = 'en'
  //   }
  //   setLang(pageLang)
  // }, [])

  return (
    <AppContext.Provider
      value={{
        lang,
        media,
        location,
        contactElm,
        isScrolled,
        handleContactElm,
        handleLang,
        handleScrollFlg,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
