import React from 'react'
import { markupBrAll } from '../../common/TextConverter'
import styles from './index.module.scss'

export const IntroductionFlowItem = ({ contents, index, lang }) => {
  return (
    <div className={styles.introduction_flow_item}>
      <img src={contents.num_src} alt={index + 1} className={styles.introduction_flow_item__num} />
      <h3 className={styles.introduction_flow_item__title}>{markupBrAll(contents.title[lang])}</h3>
      <img
        src={contents.img_src}
        alt={'img' + (index + 1)}
        className={styles.introduction_flow_item__img}
      />
    </div>
  )
}
