import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Container } from '../../components/common/Container'
import styles from './index.module.scss'

export const ErrorPage: React.FC = () => {
  const error = useRouteError()
  console.error(error)

  return (
    <div className={styles.page}>
      <Container>
        <div className={styles.page__container}>
          <h1 className={styles.page__title}>Oops!</h1>
          <p className={styles.page__desc}>Sorry, an unexpected error has occurred.</p>
          <a className={styles.page__btn} href='/'>
            main page
          </a>
        </div>
      </Container>
    </div>
  )
}
