import { useState, useEffect } from 'react'

export const useMediaQuery = () => {
  const [mq, setMq] = useState({
    isPC: window.matchMedia('screen and (max-width: 1100px)').matches,
    isTB: window.matchMedia('screen and (max-width: 850px)').matches,
    isSP: window.matchMedia('screen and (max-width: 550px)').matches,
  })

  useEffect(() => {
    const onResize = () => {
      setMq({
        isPC: window.matchMedia('screen and (max-width: 1100px)').matches,
        isTB: window.matchMedia('screen and (max-width: 850px)').matches,
        isSP: window.matchMedia('screen and (max-width: 550px)').matches,
      })
    }

    window.addEventListener('resize', onResize)
    window.addEventListener('load', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('load', onResize)
    }
  })

  return mq
}
