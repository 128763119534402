import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'

export const FadeInContainer = ({ children, className = '', isAuto = false, style = {} }) => {
  const [animateIn, setAnimateIn] = useState(false)
  const divElm = useRef(null)

  useEffect(() => {
    if (isAuto) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          startAnimation()
        }, 500)
      })
      setTimeout(() => {
        if (!animateIn) {
          startAnimation()
        }
      }, 1500)
    }
  }, [])

  const checkScroll = () => {
    if (divElm.current !== null) {
      const windowHeight = window.innerHeight
      const clientRect = divElm.current.getBoundingClientRect()
      const elementTop = clientRect.top
      if (!animateIn && elementTop < windowHeight * 0.85) {
        startAnimation()
      }
    }
  }
  const startAnimation = () => {
    setAnimateIn(true)
    window.removeEventListener('scroll', checkScroll, false)
  }

  window.addEventListener('scroll', checkScroll)

  return (
    <div
      className={styles.fadein_container + ' ' + className}
      data-animate-in={animateIn}
      ref={divElm}
      style={style}
    >
      {children}
    </div>
  )
}
