// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eTCeDidiZmZvzTxYAcGm{white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./src/layouts/BaseLayout/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA","sourcesContent":[".main {\r\n  white-space: pre-wrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "eTCeDidiZmZvzTxYAcGm"
};
export default ___CSS_LOADER_EXPORT___;
