import React from 'react'
export const BG_PARTICLE1 = () => (
  <svg
    width='268'
    height='267'
    viewBox='0 0 268 267'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M218.03 7.94714L75.8864 24.6008M218.03 7.94714L266.782 135.452M218.03 7.94714L146.866 65.8181M75.8864 24.6008L10.6881 84.9705M75.8864 24.6008L60.0274 258.793M75.8864 24.6008L146.866 65.8181M10.6881 84.9705L3.63965 182.291M10.6881 84.9705L60.0274 258.793M10.6881 84.9705L266.782 135.452M10.6881 84.9705L146.866 65.8181M3.63965 182.291L60.0274 258.793M3.63965 182.291L146.866 65.8181M60.0274 258.793L175.74 223.925M60.0274 258.793L146.866 65.8181M175.74 223.925L266.782 135.452M175.74 223.925L146.866 65.8181M266.782 135.452L146.866 65.8181'
      stroke='#D9D9E2'
      strokeWidth='0.5'
    />
    <g filter='url(#filter0_f_35_141)'>
      <circle cx='217.919' cy='8.26892' r='3.26892' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter1_f_35_141)'>
      <circle cx='75.7784' cy='24.3423' r='1.63952' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter2_f_35_141)'>
      <circle cx='147.253' cy='65.8181' r='3.26892' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter3_f_35_141)'>
      <circle cx='11.993' cy='84.6725' r='3.26892' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter4_f_35_141)'>
      <circle cx='3.63952' cy='181.736' r='1.63952' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter5_f_35_141)'>
      <circle cx='31.5111' cy='159.603' r='1.63952' fill='#D9D9E2' />
    </g>
    <g filter='url(#filter6_f_35_141)'>
      <circle cx='59.6478' cy='257.849' r='3.26892' fill='#D9D9E2' />
    </g>
    <defs>
      <filter
        id='filter0_f_35_141'
        x='209.65'
        y='0'
        width='16.5378'
        height='16.5378'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='2.5' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter1_f_35_141'
        x='72.1389'
        y='20.7028'
        width='7.27905'
        height='7.27905'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter2_f_35_141'
        x='138.984'
        y='57.5492'
        width='16.5378'
        height='16.5378'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='2.5' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter3_f_35_141'
        x='3.72412'
        y='76.4036'
        width='16.5376'
        height='16.5378'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='2.5' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter4_f_35_141'
        x='0'
        y='178.097'
        width='7.27881'
        height='7.27905'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter5_f_35_141'
        x='27.8716'
        y='155.963'
        width='7.27881'
        height='7.27905'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_35_141' />
      </filter>
      <filter
        id='filter6_f_35_141'
        x='51.3789'
        y='249.581'
        width='16.5378'
        height='16.5378'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='2.5' result='effect1_foregroundBlur_35_141' />
      </filter>
    </defs>
  </svg>
)
