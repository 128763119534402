import React from 'react'
export const BG_PARTICLE2 = () => (
  <svg
    width='177'
    height='173'
    viewBox='0 0 177 173'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_f_324_216)'>
      <path
        d='M150.562 1.8369L57.6644 6.40849M150.562 1.8369L175.077 94.3126M150.562 1.8369L101.322 39.0935M57.6644 6.40849L12.1465 45.7306M57.6644 6.40849L34.4877 170.934M57.6644 6.40849L101.322 39.0935M12.1465 45.7306L2.21879 114.077M12.1465 45.7306L34.4877 170.934M12.1465 45.7306L175.077 94.3126M12.1465 45.7306L101.322 39.0935M2.21879 114.077L34.4877 170.934M2.21879 114.077L101.322 39.0935M34.4877 170.934L111.286 152.168M34.4877 170.934L101.322 39.0935M111.286 152.168L175.077 94.3126M111.286 152.168L101.322 39.0935M175.077 94.3126L101.322 39.0935'
        stroke='#D9D9E2'
        strokeWidth='0.5'
      />
    </g>
    <defs>
      <filter
        id='filter0_f_324_216'
        x='0.959106'
        y='0.577271'
        width='175.399'
        height='171.644'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='0.5' result='effect1_foregroundBlur_324_216' />
      </filter>
    </defs>
  </svg>
)
