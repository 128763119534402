import React from 'react'
import { RequiredLabel } from '../RequiredLabel'
import styles from './index.module.scss'

export const Textarea = ({
  input_id,
  label,
  required = false,
  lang = 'en',
  onSinglePage = false,
  onChange,
}) => (
  <div className={styles.textarea}>
    <label htmlFor={input_id} className={styles.textarea__label}>
      {label}
      <RequiredLabel required={required} lang={lang} onSinglePage={onSinglePage} />
    </label>
    <textarea
      id={input_id}
      rows={10}
      className={styles.textarea__input}
      required={required}
      data-single-page={onSinglePage}
      onChange={(e) => {
        onChange(e.target.value)
      }}
    />
  </div>
)
