import React, { useContext } from 'react'
import { Container } from '../../components/common/Container'
import { LinkButton } from '../../components/common/LinkButton'
import { TEXT_BACK_BTN } from '../../utils/constants'
import { AppContext } from '../../providers/AppContext'
import styles from './index.module.scss'

export const NotFoundPage: React.FC = () => {
  const { lang } = useContext(AppContext)

  return (
    <div className={styles.page}>
      <Container>
        <div className={styles.page__container}>
          <h1 className={styles.page__title}>404</h1>
          <p className={styles.page__desc}>Sorry, the page was not found.</p>
          <LinkButton href={TEXT_BACK_BTN.href[lang]} text={TEXT_BACK_BTN.title[lang]} />
        </div>
      </Container>
    </div>
  )
}
