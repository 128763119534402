import React from 'react'
import { TEXTS_CONTACT } from '../../../utils/constants'
import styles from './index.module.scss'

export const RequiredLabel = ({ required = true, lang = 'en', onSinglePage = false }) => (
  <span className={styles.required_label} data-required={required} data-single-page={onSinglePage}>
    {onSinglePage
      ? required
        ? '*'
        : ''
      : required
      ? TEXTS_CONTACT.required[lang]
      : TEXTS_CONTACT.optional[lang]}
  </span>
)
