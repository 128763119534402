import React from 'react'
import styles from './index.module.scss'

export const ColoredLinkButton = ({ text, href, style = {} }) => {
  return (
    <a href={href} className={styles.button} style={style}>
      {text}
    </a>
  )
}
