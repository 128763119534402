import React, { Suspense, useContext, useEffect, useRef } from 'react'
import { TEXTS_MAIN } from '../../utils/constants'
import styles from './index.module.scss'
import { AppContext } from '../../providers/AppContext'
import { smoothScroll } from '../../utils/functions'
// components
import {
  markupBrAll,
  translateTextforColor,
  translateTextForWave,
} from '../../components/common/TextConverter'
import { ContactForm } from '../../components/contact/ContactForm'
import { Container } from '../../components/common/Container'
import { PointItem } from '../../components/Point/PointItem'
import { TEXTS_HEADER } from '../../utils/constants'
import { ScrollButton } from '../../components/common/ScrollButton'
import { FadeInContainer } from '../../components/common/animation/fadeInContainer'
const FlowSection = React.lazy(() => import('../../components/Flow/FlowSection'))
const FlowComponent = React.lazy(() => import('../../components/Flow/FlowComponent'))
// images
import TOP_BG from '../../images/top_bg.jpg'
import AVATAR_SRC1 from '../../images/avatar.png'
import AVATAR_SRC2 from '../../images/avatar2.png'
import AVATAR_SRC3 from '../../images/avatar-green.png'
import AVATAR_SRC4 from '../../images/avatar_sp_tb.png'
import AVATAR_SRC5 from '../../images/avatar-cut-version.png'
import SCROLL_IMG from '../../images/scroll.png'
import { IntroductionFlowItem } from '../../components/IntroductionFlow/IntroductionFlowItem'
// import TOP_VIDEO from '../../images/top/top_video.mp4'
// import TOP_VIDEO from '../../images/top/top-video-2x.mp4'
// import TOP_VIDEO_SIDE from '../../images/top/top-video-side-2x.mp4'
import TOP_FRAME from '../../images/top/top_frame.png'
import TOP_FRAME_SP from '../../images/top/top_frame_sp.png'
import TOP_AVATAR from '../../images/top/top_avatar.mp4'
import TOP_VIDEO from '../../images/top/3x.mp4'
import TOP_VIDEO_SIDE from '../../images/top/side-3x.mp4'
import AVATAR_GIF from '../../images/avatar-gif.mp4'
import { BG_PARTICLE1 } from '../../images/bg_particle1'
import { BG_PARTICLE2 } from '../../images/bg_particle2'
import { getContactLink } from '../../utils/getLinks'
import { ColoredLinkButton } from '../../components/common/ColoredLinkButton'
import { MainContactLink } from '../../components/common/MainContactLink'

export const LandingPage: React.FC = () => {
  const { lang, media, location, isScrolled, handleContactElm } = useContext(AppContext)
  const contactElm = useRef(null)
  const videoRefPC = useRef<HTMLVideoElement>(null)
  const videoRefSP = useRef<HTMLVideoElement>(null)
  // const location = useLocation()
  useEffect(() => {
    if (contactElm.current !== null) {
      handleContactElm(contactElm.current)
    }
    setTimeout(() => {
      if (location.hash !== '') {
        smoothScroll(location.hash)
      }
    }, 500)
  }, [])

  setTimeout(() => {
    videoRefPC.current?.play()
    videoRefSP.current?.play()
  }, 2500)

  return (
    <>
      {/* style={{ backgroundImage: `url(${TOP_BG})` }} */}
      <section className={styles.top}>
        <Container>
          <div className={styles.top__container}>
            <div className={styles.top__container_content_1}>
              <h1 className={styles.top__title_1} data-lang={lang}>
                {media !== 'sp' && media != 'tb' && (
                  <div className={styles.top__bg1}>
                    <BG_PARTICLE1 />
                  </div>
                )}
                {media !== 'sp' && media != 'tb' && (
                  <div className={styles.top__bg2}>
                    <BG_PARTICLE2 />
                  </div>
                )}
                {/* {translateTextforColor(
                  TEXTS_MAIN.top_title_1[lang],
                  lang === 'en' ? false : true,
                  false,
                  true,
                )}
                {translateTextforColor(
                  TEXTS_MAIN.top_title_1_1[lang],
                  lang === 'jp' ? false : true,
                  false,
                  true,
                )} */}
              </h1>
              <h1 className={styles.top__title_2} data-lang={lang}>
                {/* <h1 className={styles.top__title_3} data-lang={lang}>
                  {translateTextforColor(TEXTS_MAIN.top_title_2_1[lang], false, false, true)}
                </h1> */}
                {translateTextforColor(TEXTS_MAIN.top_title_2_2[lang], false, false, true)}
              </h1>
              <h1 className={styles.top__title_2} data-lang={lang}>
                {/* <h1 className={styles.top__title_3} data-lang={lang}>
                  {translateTextforColor(TEXTS_MAIN.top_title_2_4[lang], false, false, true)}
                </h1> */}
                {translateTextforColor(TEXTS_MAIN.top_title_2_3[lang], false, false, true)}
              </h1>
              <FadeInContainer
                className={lang === 'en' ? styles.top__desc_en : styles.top__desc_jp}
                isAuto={true}
                style={{ transitionDelay: '0.4s' }}
              >
                {markupBrAll(TEXTS_MAIN.top_desc[lang])}
              </FadeInContainer>
              {media !== 'sp' && media != 'tb' && (
                <div className={styles.top__bg3}>
                  <svg
                    width='144'
                    height='95'
                    viewBox='0 0 144 95'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g filter='url(#filter0_f_324_217)'>
                      <path
                        d='M56.5 3L3 44.5M56.5 3L101.5 22.5M56.5 3L40.5 92.5M3 44.5L40.5 92.5M3 44.5L141.5 64M3 44.5L101.5 22.5M40.5 92.5L141.5 64M40.5 92.5L101.5 22.5M141.5 64L101.5 22.5'
                        stroke='#D9D9E2'
                        strokeWidth='0.5'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_f_324_217'
                        x='0.64856'
                        y='0.711792'
                        width='143.321'
                        height='94.0743'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                      >
                        <feFlood floodOpacity='0' result='BackgroundImageFix' />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='BackgroundImageFix'
                          result='shape'
                        />
                        <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_324_217' />
                      </filter>
                    </defs>
                  </svg>
                </div>
              )}
              <FadeInContainer
                className={styles.top__contact}
                isAuto={true}
                style={{ transitionDelay: '0.4s' }}
              >
                <MainContactLink
                  text={TEXTS_MAIN.contact_title_main[lang]}
                  href={getContactLink(lang)}
                  lang={lang}
                />
              </FadeInContainer>
            </div>

            {/* <FadeInContainer
              className={styles.top__video_container}
              isAuto={true}
              style={{ transitionDelay: '0.6s' }}
            >
              <video
                className={styles.top__video}
                src={AVATAR_GIF}
                autoPlay={true}
                muted={true}
                playsInline={true}
                loop={true}
              />
            </FadeInContainer> */}
            {media !== 'sp' && media !== 'tb' ? (
              <FadeInContainer
                className={styles.top__top_image_container_pc}
                isAuto={true}
                style={{ transitionDelay: '0.6s' }}
              >
                <div className={styles.top__container_content_2}>
                  <div className={styles.top__top_ui}>
                    <img
                      className={styles.top__top_image}
                      src='https://storage.googleapis.com/selfy-lp.appspot.com/images/top-avatar-asset/top_frame_pc.png'
                    />
                    <video
                      className={styles.top__video}
                      ref={videoRefSP}
                      src='https://storage.googleapis.com/selfy-lp.appspot.com/images/top-avatar-asset/top_avatar.mp4'
                      muted={true}
                      playsInline={true}
                      loop={true}
                    />
                  </div>
                </div>
              </FadeInContainer>
            ) : (
              ''
            )}
          </div>
          {media !== 'sp' && media !== 'tb' ? null : (
            <FadeInContainer
              className={styles.top__top_image_container_pc}
              isAuto={true}
              style={{ transitionDelay: '0.6s', overflow: media === 'sp' ? 'none' : 'hidden' }}
            >
              <div className={styles.top__top_ui}>
                <img
                  className={styles.top__top_image}
                  src='https://storage.googleapis.com/selfy-lp.appspot.com/images/top-avatar-asset/top_frame_sp.png'
                />
                <video
                  className={styles.top__video_sp}
                  ref={videoRefPC}
                  src='https://storage.googleapis.com/selfy-lp.appspot.com/images/top-avatar-asset/top_avatar.mp4'
                  muted={true}
                  playsInline={true}
                  loop={true}
                />
              </div>
            </FadeInContainer>
          )}
        </Container>
        <img
          className={styles.top__scroll}
          src={SCROLL_IMG}
          alt='scroll'
          data-scrolled={isScrolled}
        />
      </section>
      {/* <section className={styles.about}>
        {/* <div className={styles.about__subtitle}>ABOUT</div> *
        <Container>
          <h2 className={styles.about__title}>
            {translateTextForWave(TEXTS_MAIN.about_title[lang])}
          </h2>
          {/* <FadeInContainer className={styles.about__desc}>
            {markupBrAll(TEXTS_MAIN.about_desc[lang])}
          </FadeInContainer> 
        </Container>
      </section> */}
      {/* <section className={styles.about}>
        {/* <div className={styles.about__subtitle}>ABOUT</div> *
        <Container>
          <h2 className={styles.about__flowtitle}>
            {translateTextForWave(TEXTS_MAIN.about_flowtitle[lang])}
          </h2>
        </Container>
      </section> */}
              <div className={styles.about__flowtitle_container}>
      <Container>

        <h2 className={styles.about__flowtitle}>
          {/* {translateTextForWave(TEXTS_MAIN.about_flowtitle[lang])} */}
          {markupBrAll(TEXTS_MAIN.about_flowtitle[lang])}
        </h2>
        <h2 className={styles.about__flowtitle_2}>
          {markupBrAll(TEXTS_MAIN.about_flowtitle_2[lang])}
        </h2>
      </Container>
      </div>

      <section className={styles.flow}>
        <Suspense fallback={<div>Loading...</div>}>
          {
            // media === 'sp' || media ==='tb' || media ==='pc'?
            window.innerWidth < 1300 ? <FlowSection /> : <FlowComponent />
          }
        </Suspense>
      </section>

      {/* <section className={styles.point}>
        {TEXTS_MAIN.points.map((point, index) => (
          <PointItem key={index} contents={point} index={index} lang={lang} />
        ))}
      </section> */}
      <section className={styles.appeal}>
        <Container>
          <h2 className={styles.appeal__title}>
            {/* {translateTextForWave(TEXTS_MAIN.appeal_title[lang])} */}
            {markupBrAll(TEXTS_MAIN.appeal_title[lang])}
          </h2>

          <FadeInContainer className={styles.appeal__flow}>
            {TEXTS_MAIN.introduction_flow.map((item, index) => (
              <IntroductionFlowItem key={index} contents={item} index={index} lang={lang} />
            ))}
          </FadeInContainer>
        </Container>
      </section>
      {/* <section id='contact' className={styles.contact} ref={contactElm}>
        <Container>
          <h2 className={styles.contact__title}>{TEXTS_MAIN.contact_title_form[lang]}</h2>
          <FadeInContainer className={styles.appeal__desc} isAuto={true}>
            {markupBrAll(TEXTS_MAIN.appeal_desc[lang])}
          </FadeInContainer>
          <ContactForm lang={lang} />
        </Container>
      </section> */}
    </>
  )
}
