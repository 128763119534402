import React from 'react'
import styles from './index.module.scss'

export const Container = ({ children, style = {} }) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  )
}
