export const smoothScroll = (href) => {
  const speed = 200
  const divisor = 100
  const tolerance = 5
  const headerHeight = 0
  const interval = speed / divisor

  let nowY = window.pageYOffset
  const target = document.querySelector(href)
  if (target != null) {
    const targetRectTop = target.getBoundingClientRect().top
    const targetY = targetRectTop + nowY - headerHeight
    const minY = Math.abs((targetY - nowY) / divisor)
    doScroll(minY, nowY, targetY, tolerance, interval)
  }
}

const doScroll = (minY, nowY, targetY, tolerance, interval) => {
  let toY
  if (targetY < nowY) {
    toY = nowY - minY
  } else {
    toY = nowY + minY
  }
  window.scrollTo(0, toY)
  if (targetY - tolerance > toY || toY > targetY + tolerance) {
    window.setTimeout(doScroll, interval, minY, toY, targetY, tolerance, interval)
  } else {
    return false
  }
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}
