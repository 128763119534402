import React, { useContext } from 'react'
import { AppContext } from '../../../providers/AppContext'
import { ColoredTitle } from '../animation/ColoredTitle'
import { WavingTitle } from '../animation/WavingTitle'

export const translateTextforColor = (
  text: string,
  isColoredText = false,
  isWhiteText = false,
  isAuto = false,
) => {
  const largeStyledJSX = markupLargeSpan(text)
  const animationStyledJSX = markupColoredAnimation(
    largeStyledJSX,
    isColoredText,
    isWhiteText,
    isAuto,
  )
  console.log('checkk herer>>', text, isColoredText, largeStyledJSX)

  return <>{animationStyledJSX}</>
}
export const translateTextforLarge = (text: string) => {
  const largeStyledJSX = markupLargeSpan(markupBrAll(text))

  return <>{largeStyledJSX}</>
}
export const translateTextForWave = (text: string, isWhiteText = false, isAuto = false) => {
  const removedText = text.replaceAll(/¥\/?a/g, '')
  const taggedJSX = markupBrAll(removedText)

  return <WavingTitle text={taggedJSX} isWhiteText={isWhiteText} isAuto={isAuto} />
}

export const markupBrAll = (texts) => {
  const { media } = useContext(AppContext)
  let arr: (JSX.Element | string)[] = []
  let result: (JSX.Element | string)[] = []
  if (typeof texts === 'string') {
    arr.push(texts)
  } else if (texts === undefined) {
    return ['']
  } else {
    arr = texts
  }

  arr.map((item) => {
    if (typeof item === 'string') {
      let removedText = ''
      if (media === 'tb') {
        removedText = item.replaceAll('¥z', '')
      } else if (media === 'pc') {
        removedText = item.replaceAll('¥z', '').replaceAll('¥y', '')
      } else if (media === 'dt') {
        removedText = item.replaceAll('¥z', '').replaceAll('¥y', '').replaceAll('¥x', '')
      } else {
        removedText = item
      }
      result = result.concat(removedText)
    } else {
      result.push(item)
    }
  })
  const forDT = markupBr(result, '¥w')
  const forPC = markupBr(forDT, '¥x')
  const forTB = markupBr(forPC, '¥y')
  return markupBr(forTB, '¥z')
}

export const markupBr = (texts, replacedText = '¥w') => {
  let arr: (JSX.Element | string)[] = []
  let result: (JSX.Element | string)[] = []
  let media = ''
  switch (replacedText) {
    case '¥w':
      media = 'all'
      break
    case '¥x':
      media = 'pc'
      break
    case '¥y':
      media = 'tb'
      break
    case '¥z':
      media = 'sp'
      break
    default:
      media = 'pc'
      break
  }

  if (typeof texts === 'string') {
    arr.push(texts)
  } else {
    arr = texts
  }

  arr.map((item) => {
    if (typeof item == 'string' && item.match(replacedText)) {
      const splittedItem: (JSX.Element | string)[] = item.split(replacedText)
      const originalLength = splittedItem.length
      for (let i = 0; i < originalLength - 1; i++) {
        splittedItem.splice(i * 2 - 1, 0, <br key={`${item}${i}`} data-for={media} />)
      }
      result = result.concat(splittedItem)
    } else {
      result.push(item)
    }
  })

  return result
}

export const markupLargeSpan = (texts) => {
  let arr: (JSX.Element | string)[] = []
  if (typeof texts === 'string') {
    arr.push(texts)
  } else {
    arr = texts
  }

  return arr.map((item) => {
    if (typeof item == 'string' && item.match(/¥\/?l/)) {
      return item.split(/¥\/?l/).map((splittedItem, index) =>
        index % 2 === 1 ? (
          <span data-large='true' key={index}>
            {splittedItem}
          </span>
        ) : (
          splittedItem
        ),
      )
    } else {
      return item
    }
  })
}

const markupColoredAnimation = (
  texts,
  isColoredText = false,
  isWhiteText = false,
  isAuto = false,
) => {
  let arr: (JSX.Element | string)[] = []
  if (typeof texts === 'string') {
    arr.push(texts)
  } else {
    arr = texts
  }
  let output = arr.map((itemX) => {
    if (typeof itemX === 'string' && itemX.match(/¥\/?a/)) {
      return itemX.split(/¥\/?a/).map((itemY, indexY) => {
        if (indexY % 2 === 1) {
          if (itemY.match(/¥[xyz]/)) {
            return markupBrAll(itemY).map((itemZ, indexZ) => {
              return typeof itemZ === 'string' ? (
                <ColoredTitle
                  key={`${indexY}-${indexZ}`}
                  text={itemZ}
                  isColoredText={isColoredText}
                  isWhiteText={isWhiteText}
                  isAuto={isAuto}
                />
              ) : (
                itemZ
              )
            })
          } else {
            return (
              <ColoredTitle
                key={indexY}
                text={itemY}
                isColoredText={isColoredText}
                isWhiteText={isWhiteText}
                isAuto={isAuto}
              />
            )
          }
        } else {
          return markupBrAll(itemY)
        }
      })
    } else {
      return itemX
      // return (
      //   <ColoredTitle
      //     // key={indexY}
      //     text={itemX}
      //     isColoredText={isColoredText}
      //     isWhiteText={isWhiteText}
      //     isAuto={isAuto}
      //   />
      // )
    }
  })
  return output
}
