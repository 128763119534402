// import FLOW1_SRC from '../images/selfy1.mp4'
// import FLOW2_SRC from '../images/selfy2.mp4'
// import FLOW3_SRC from '../images/selfy3.mp4'
import FLOW1_SRC from '../images/flow_video/selfy_1.mp4'
import FLOW2_SRC from '../images/flow_video/selfy_2.mp4'
import FLOW3_SRC from '../images/flow_video/selfy_3.mp4'
import FLOW_NUM1_SRC from '../images/flow_num1.png'
import FLOW_NUM2_SRC from '../images/flow_num2.png'
import FLOW_NUM3_SRC from '../images/flow_num3.png'
import POINT1_SRC from '../images/point1.png'
import POINT2_SRC from '../images/point2.png'
import POINT3_SRC from '../images/point3.png'
import OGP_SRC from '../images/selfy_ogp.jpg'
import INTRO_FLOW1_SRC from '../images/intro_flow1.png'
import INTRO_FLOW2_SRC from '../images/intro_flow2.png'
import INTRO_FLOW3_SRC from '../images/intro_flow3.png'
import INTRO_NUM1_SRC from '../images/intro_num1.png'
import INTRO_NUM2_SRC from '../images/intro_num2.png'
import INTRO_NUM3_SRC from '../images/intro_num3.png'

// ¥a ¥/a : animation
// ¥l ¥/l : largetext
// ¥w : <br/> for dt/pc/tb/sp
// ¥x : <br/> for pc/tb/sp
// ¥y : <br/> for tb/sp
// ¥z : <br/> for sp
export const LANG_LIST = ['en', 'jp']
export const TEXTS_HEADER = {
  home: { en: 'Home', jp: 'ホーム' },
  contact: { en: 'Contact us', jp: 'お問合せ' },
  demo: { en: 'Get Started For Free', jp: '無料で始める' },
  try_demo: { en: 'Try Demo', jp: 'デモを試す' },
  plan: { en: 'Pricing', jp: '料金プラン' },
}
export const TEXTS_MAIN = {
  top_title_1: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: `¥aMaking¥/a `,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aアパレルEC¥/a`,
  },
  top_title_1_1: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: `¥aFashion EC¥/a`,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aを¥/a`,
  },
  top_title_2_1: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: `¥amore¥/a `,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aより¥/a`,
  },
  // top_title_2_2: {
  //   // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
  //   en: ` ¥aFun,¥/a`,
  //   // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
  //   // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
  //   jp: `¥a楽しく、¥/a`,
  // },
  // top_title_2_3: {
  //   // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
  //   en: ` ¥aConvenient.¥/a`,
  //   // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
  //   // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
  //   jp: `¥a便利に。¥/a`,
  // },
  top_title_2_2: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: ` ¥aTry-on experience¥/a`,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aアパレルECに¥/a`,
  },
  top_title_2_3: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: ` ¥afor fashion EC¥/a`,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥a試着体験を。¥/a`,
  },
  top_title_2_4: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: `¥amore¥/a `,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aより¥/a`,
  },
  top_title: {
    // en: `¥aFashion EC¥/a¥w¥amade fun¥/a¥w¥aand easy.¥/a`,
    en: `¥aFashion EC made¥/a¥w¥amore fun,¥/a¥w¥amore convenient.¥/a`,
    // en: `¥aOnline¥/a¥w¥ashopping made¥a¥w¥afun and easy.`,
    // jp: `¥a未来の試着が¥/a¥w¥aここに。¥/a`,
    jp: `¥aアパレルECを¥/a¥w¥aより楽しく、¥/a¥w¥aより便利に。¥/a`,
  },
  top_desc: {
    // en: `Selfy is a 3D Avatar-based¥wonline fitting service that helps¥wboost your conversion rates¥wand make your customers happy.`,
    en: `Selfy is an online try-on service ¥wthat lets your customers ¥wfind the perfect size and fit of the clothes.`,
    jp: `Selfyはお客様に完璧なサイズ・着用感の服の¥w提供を可能にするオンライン試着サービスです。`,
  },
  about_title: {
    en: `¥aAvatars are 3D,¥wClothes are 3D,¥/a¥w¥aThis is real ¥zonline try-on.¥/a`,
    jp: `¥aアバターも３D。¥x服も３D。¥/a¥w¥aこれが本当の¥xオンライン試着。¥/a`,
  },
  about_desc: {
    en: `I'm not sure of the right size fit for me...¥wIt's not so easy to buy fashion items online...¥w"Selfy" is the online try-on service that solves such problems of the customers.¥wWith the unprecedented 3D x 3D online try-on technology,¥wyour customers can choose clothes that truly suit them¥wwithout compromising the attractiveness of the item.`,
    jp: `どのサイズが自分に合うのかわからない…¥wオンラインでファッションアイテムを¥z購入するのが難しい…¥wそんなお客様の悩みを解決するのが¥wオンライン試着サービス「Selfy」です。¥wこれまでにない、¥z３D×３Dのオンライン試着で¥wアイテムの魅力を損なわず、¥z本当に合う服を選んでいただけます。`,
  },
  about_flowtitle: {
    en: `Customers can try-on before ¥wbuying with a 3D Avatar ¥wof their body shape. `,
    jp: `顧客の体系の3Dアバターで¥w購入前にオンライン試着¥yが簡単に。`,
  },
  about_flowtitle_2: {
    en: `Increase customer loyalty and purchase rates  ¥x& reduce return rates.`,
    jp: `顧客ロイヤリティや購買率の向上、¥x返品率の改善につながります。`,
  },
  flows: [
    {
      title: {
        en: `Access Selfy¥wfrom EC Store`,
        jp: `ECサイトから¥wSelfyにアクセス`,
      },
      desc: {
        en: `Once you find clothes you want to try-on,¥wvisit the product page and click¥won the button to access Selfy`,
        jp: `試着したい服が決まったら¥w服の詳細ページのボタンからアクセス。`,
      },
      title_src: FLOW_NUM1_SRC,
      vid_src_en: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/en/en_1.mp4',
      vid_src_ja: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/ja/ja_1.mp4',
    },
    {
      title: {
        en: `Enter required details`,
        jp: `必要な情報を入力`,
      },
      desc: {
        en: `In addition to basic details like height,¥wweight, age, etc, and your body shape.`,
        jp: `身長、体重、年齢等の基本情報に加えて¥w体系情報を入力。`,
      },
      title_src: FLOW_NUM2_SRC,
      vid_src_en: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/en/en_2.mp4',
      vid_src_ja: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/ja/ja_2.mp4',
    },
    {
      title: {
        en: `Try-on with the ¥wgenerated avatar`,
        jp: `完成したアバターで¥wオンライン試着`,
      },
      desc: {
        en: `Recommends two best size fits.¥wRotate avatar in 360° to compare¥wthe cloth sizes.`,
        jp: `2番目までのおすすめサイズを提示¥w360度回転させてサイズの確認が可能。`,
      },
      title_src: FLOW_NUM3_SRC,
      vid_src_en: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/en/en_3.mp4',
      vid_src_ja: 'https://storage.googleapis.com/selfy-lp.appspot.com/flow_videos/ja/ja_3.mp4',
    },
  ],
  points: [
    {
      title: {
        en: `¥aProviding the¥z best of both¥/a¥w¥a Online Try-on¥z and Metaverse.¥/a`,
        jp: `¥a既存の¥yオンライン試着と¥/a¥w¥aメタバースの¥xいいとこどり。¥/a`,
      },
      desc: {
        en: `A more intuitive and a better shopping experience is now possible¥wand it enables great user engagement,¥wimproved conversion rates¥z and slashing the returns`,
        jp: `より直感的で正確な¥z購買体験を実現するため、¥wこれまでのオンライン試着サービスに¥wメタバースの要素を組み合わせました。`,
      },
      src: POINT1_SRC,
    },
    {
      title: {
        en: `¥aHigh¥z Performance AI,¥/a¥w¥aHigh¥z Quality 3D.¥/a`,
        jp: `¥a高性能AI、¥/a¥w¥a高クオリティ３D。¥/a`,
      },
      desc: {
        en: `With AI for body shape prediction & 3D avatars to represent¥wthe customer's body shape¥z close to real life,¥wour technology paves the way for¥wan unprecedented¥z online fitting experience.`,
        jp: `お客様の体型を瞬時に予測するAIと¥wまるでホンモノのような３Dアバターで、¥wこれまでにないオンライン試着体験を¥w可能にします。`,
      },
      src: POINT2_SRC,
    },
    {
      title: {
        en: `¥aIntegration¥z process is¥/a¥w¥asmooth and¥z stress-free.¥/a`,
        jp: `¥a導入も試着も¥/a¥w¥a超ストレスフリー。¥/a`,
      },
      desc: {
        en: `Our service can be implemented¥z in your online store¥wwithin 14 days after the request.¥wWorks smoothly in any browser, and totally stress-free.`,
        jp: `ご依頼後約14日以内で導入可能です。¥wどのブラウザーでもサクサク動き、¥wストレスを感じません。`,
      },
      src: POINT3_SRC,
    },
  ],
  appeal_title: {
    // en: `¥aTranform¥z your customers'¥/a¥w¥a online shopping¥z experience today.¥/a`,
    en: `Easily add online try-on¥wto your EC Store.`,
    jp: `ECサイトに¥zオンライン試着を¥w簡単に導入可能です。`,
  },
  appeal_desc: {
    en: `Get in touch to learn how Selfy can accelerate your online sales.¥wFeel free to contact us below for the pricing, plans, demonstration etc.`,
    jp: `金額・プラン等のご相談、¥zデモのリクエストなど、¥wぜひ、お気軽にお問い合わせください。`,
  },
  introduction_flow: [
    {
      title: {
        en: 'Send us images of¥wyour products and blueprints',
        jp: '商品の画像や設計図などの¥w参考資料を送る',
      },
      num_src: INTRO_NUM1_SRC,
      img_src: INTRO_FLOW1_SRC,
    },
    {
      title: {
        en: 'Selfy generates¥wembedding code for 3D content',
        jp: 'Selfyが3Dコンテンツの¥w埋め込みコードを生成',
      },
      num_src: INTRO_NUM2_SRC,
      img_src: INTRO_FLOW2_SRC,
    },
    {
      title: {
        en: 'Paste the generated code into your page¥wto complete the installation!',
        jp: '生成されたコードをページに¥w貼り付けて導入完了！',
      },
      num_src: INTRO_NUM3_SRC,
      img_src: INTRO_FLOW3_SRC,
    },
  ],
  contact_title_main: {
    en: "Let's Talk",
    // en: "Request a Demo",
    jp: '導入相談',
  },
  contact_title_form: {
    en: 'Contact',
    jp: 'お問い合わせ',
  },
}
export const TEXTS_CONTACT = {
  contents: [
    { id: 'name', type: 'text', en: 'Your Name', jp: '氏名、担当者名', is_required: true },
    { id: 'email', type: 'text', en: 'Email', jp: 'メールアドレス', is_required: true },
    {
      id: 'company',
      type: 'text',
      en: 'Organization Name / Department name',
      jp: '組織名、部署名',
      is_required: false,
    },
    { id: 'message', type: 'textarea', en: 'Message', jp: 'お問合わせ内容', is_required: true },
  ],
  caution: {
    en: 'Please make sure that the information you entered is correct before clicking the "Send" button.',
    jp: '入力内容にお間違いがないかご確認の上、¥y送信ボタンを押してください。',
  },
  submit_btn: {
    en: 'Submit',
    jp: '送信',
  },
  required: {
    en: 'required',
    jp: '必須',
  },
  optional: {
    en: 'optional',
    jp: '任意',
  },
}
export const TEXTS_FOOTER_LINKS = [
  {
    title: {
      jp: '個人情報保護方針',
      en: 'Privacy Policy',
    },
    href: {
      jp: '/jp/privacy',
      en: '/en/privacy',
    },
    openTab: false,
  },
  {
    title: {
      jp: '運営会社',
      en: 'Company',
    },
    href: {
      jp: 'https://nectai.com/ja',
      en: 'https://nectai.com/',
    },
    openTab: true,
  },
  {
    title: {
      jp: 'English',
      en: '日本語',
    },
    href: {
      jp: '/en',
      en: '/jp',
    },
    openTab: false,
  },
]
export const TEXT_BACK_BTN = {
  href: {
    jp: '/jp',
    en: '/',
  },
  title: {
    jp: 'トップへ戻る',
    en: 'main page',
  },
}
export const META_DATA = {
  title: {
    jp: 'Prodpics.ai - Create high quality on-model images',
    en: 'Prodpics.ai - Create high quality on-model images',
  },
  description: {
    jp: 'オンラインショッピングをより楽しく便利にするために。AI体型予測と3Dアバターでオンライン試着を再発明しました。',
    en: 'To make online shopping more fun and convenient, we reinvented online try-on with AI body shape prediction and 3D avatars.',
  },
  url: 'https://selfy.app',
  ogp: {
    src: OGP_SRC,
    width: 5000,
    height: 2625,
  },
}
export const TEXT_PLANPAGE = {
  title: {
    jp: '料金プラン',
    en: 'Pricing',
  },
  desc: {
    jp: '現在はベータ版のため、初期・月額費用が発生しないフリープランのみご提供しています。',
    en: 'Currently, only free plans with no initial or monthly fees are available due to the beta version.',
  },
  plan1: {
    title: {
      jp: 'Free',
      en: 'Free',
    },
    desc: {
      jp: undefined,
      en: undefined,
    },
    price: {
      jp: '¥¥l0¥/l',
      en: '$¥l0¥/l',
    },
    points: [
      { jp: '月額費用無料', en: 'No monthly fee' },
      { jp: '毎月最大5アイテムまで', en: 'Up to 5 items per month' },
      { jp: '機能制限なし(ベータ版期間中)', en: 'Unlimited functionality (during beta version)' },
    ],
  },
  plan2: {
    title: {
      jp: 'Premium',
      en: 'Premium',
    },
    desc: {
      jp: 'coming soon',
      en: 'coming soon',
    },
    price: {
      jp: undefined,
      en: undefined,
    },
    points: [],
  },
  toContact: {
    jp: '相談する',
    en: 'Talk to us',
  },
}
export const TEXT_CONTACTPAGE = {
  title: {
    jp: 'お問合せ',
    en: 'Contact',
  },
  desc: {
    en: `Get in touch to learn how Selfy can accelerate your online sales.¥wFeel free to contact us below for the pricing, plans, demonstration etc.`,
    jp: `金額・プラン等のご相談、¥zデモのリクエストなど、¥wぜひ、お気軽にお問い合わせください。`,
  },
}
