import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'

export const ColoredTitle = ({
  text,
  isColoredText = false,
  isWhiteText = false,
  isAuto = false,
}) => {
  const [animateIn, setAnimateIn] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const spanElm = useRef(null)

  useEffect(() => {
    if (isAuto) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          startAnimation()
        }, 500)
      })
      setTimeout(() => {
        if (!animateIn) {
          startAnimation()
        }
      }, 1500)
    }
  }, [])

  const checkScroll = () => {
    if (spanElm.current !== null) {
      const windowHeight = window.innerHeight
      const clientRect = spanElm.current.getBoundingClientRect()
      const elementTop = clientRect.top
      if (!animateIn && elementTop < windowHeight * 0.9) {
        startAnimation()
      }
    }
  }
  const startAnimation = () => {
    setAnimateIn(true)
    window.removeEventListener('scroll', checkScroll, false)
    setTimeout(() => {
      setAnimateOut(true)
    }, 400)
  }

  window.addEventListener('scroll', checkScroll)

  return (
    <p
      className={styles.colored_title}
      // data-animate-in={animateIn}
      data-animate-out={animateOut}
      data-is-colored-text={isColoredText}
      data-is-white-text={isWhiteText}
    >
      <span className={styles.colored_title__text} ref={spanElm}>
        {text}
      </span>
    </p>
  )
}
