import React, { useContext } from 'react'
import styles from './index.module.scss'
// components
import { Container } from '../../components/common/Container'
import { AppContext } from '../../providers/AppContext'

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <section className={styles.privacy}>
        <Container style={{ maxWidth: '850px', padding: '160px 32px 64px' }}>
          <section>
            <h1>個人情報保護指針</h1>
            <div>
              NectAI株式会社（以下「当社」とします）は、当社が取得する個人情報の取扱いに関し、個人情報保護指針（以下「本指針」とします）を定め、これを遵守します。
            </div>
          </section>
          <section>
            <h2>1. 総則</h2>
            <h3>用語の定義</h3>
            <div>
              本指針において用いる各用語の定義は、別段の定めがない限り、本指針を公開した時点において効力を有する日本国の個人情報の保護に関する法律（平成十五年法律第五十七号）の定義によるものとします。
            </div>
            <h3>指針への同意</h3>
            <div>
              当社サービスのご利用者様は、異議なく当社サービスをご利用することにより、本指針に基づく個人情報の取り扱いについて、ご同意をされたものとみなします。
              <br />
              なお本指針にご同意いただけない場合、ご利用者様は当社に個人情報を提供しないことができます。この場合、当社サービスの仕様により、その機能の全部又は一部が利用できない場合がございます。
            </div>
            <h3>指針の変更</h3>
            <div>
              当社における個人情報管理の維持向上を目的として、本指針は予告なく変更される場合があります。本指針を変更した場合、変更の内容および効力発生時期について、当社ホームページ上で公表します。
              <br />
              当社サービスのご利用者様は、異議なく当社サービスをご利用することにより、当該変更に基づく個人情報の取り扱いについて、ご同意をされたものとみなします。
            </div>
          </section>
          <section>
            <h2>2. 個人情報の取り扱い</h2>
            <h3>個人情報の適正取得</h3>
            <div>当社は、本人の同意の下に、個人情報を適正に取得します。</div>
            <h3>要配慮個人情報の取得</h3>
            <div>
              当社は、次に掲げる特定の個人情報については、あらかじめご本人から別途ご同意をいただいた場合または法令上必要な場合を除いて、取得しないものとします。
              <ol>
                <li>人種</li>
                <li>信条</li>
                <li>社会的身分</li>
                <li>病歴</li>
                <li>犯罪の経歴</li>
                <li>犯罪により害を被った事実</li>
                <li>本人に対して刑事事件に関する手続きが行われたこと</li>
              </ol>
            </div>
            <h3>個人情報の利用目的</h3>
            <div>
              当社は、当社サービスのご利用者様から取得した個人情報について、それぞれ次に掲げる事項を利用目的としてこれを取り扱います。
              <ol>
                <li>
                  身長、体重、体型、年齢、性別等の身体情報　
                  <ul>
                    <li>ECサイト上でのオンライン試着用の3Dアバターを生成するため</li>
                    <li>
                      匿名化または統計処理の上、当社サービスの向上を目的とする調査・分析のため
                    </li>
                  </ul>
                </li>
                <li>
                  メールアドレス等の連絡先情報
                  <ul>
                    <li>ダイレクトメールによる当社サービスに関するご案内のため</li>
                    <li>当社サービスに関するカスタマーサポートのご提供のため</li>
                    <li>当社サービスに関するお問い合わせ、お申込み等へのご対応のため</li>
                    <li>当社サービスの向上のためのアンケート調査のため</li>
                  </ul>
                </li>
                <li>
                  当社サービスおよび当社アプリ内での行動履歴
                  <ul>
                    <li>購買履歴・閲覧履歴に基づき、試着アイテムに関するご提案をするため</li>
                    <li>
                      匿名化または統計処理の上、当社サービスの向上を目的とする調査・分析のため
                    </li>
                    <li>当社サービスおよび当社アプリの利用状況の監視および保守のため</li>
                  </ul>
                </li>
              </ol>
            </div>
            <h3>個人関連情報の自動収集</h3>
            <div>
              当社は、当社サービスのご提供にあたり、そのシステムに要する通信の接続のため、次に掲げる個人関連情報を自動的に取得する場合があります。
              <ol>
                <li>ご利用者様の通信端末のグローバルIPアドレス、ポート番号</li>
                <li>ご利用者様の通信端末のOS、ブラウザ、ハードウェア機器、言語設定</li>
                <li>ご利用者様の通信端末が使用するモニターの画面情報（表示サイズ、対応カラー）</li>
                <li>その他、通信プロトコルおよびJavaScriptの稼働のため自動的に取得される情報</li>
              </ol>
            </div>
            <h3>Cookieの使用</h3>
            <div>
              当社は、当社サービスのご提供にあたり、Cookieによりお客様の閲覧履歴等の個人情報および個人関連情報を取得する場合があります。
              <br />
              Cookieは、お客様のウェブブラウザに保存された情報およびそれを使用した当社サービスとの通信プロトコルを指し、アクセスユーザーの識別およびカスタム設定の保存ならびにお客様の好みに応じたサービスのご提供に役立ちます。
              <br />
              お客様は、すべてのCookieを拒否するようにブラウザを設定するか、又はCookieの送信時にその旨が表示されるように設定することができます。ただしお客様がCookieを受け入れない場合、当社サービスの一部の機能が使用できない場合があります。
            </div>
            <h3>サービスの分析</h3>
            <div>
              当社は、当社サービスの保守および品質の維持向上を目的として、次に掲げる分析サービスを利用する場合があります。
              <ol>
                <li>
                  Google Analytics（
                  <a
                    target='_blank'
                    rel='noreferrer noopener'
                    href='https://marketingplatform.google.com/about/analytics/terms/jp/'
                  >
                    利用規約
                  </a>
                  ）
                  <br />
                  ブラウザ上の設定によりGoogle Analyticsを無効化することが可能です。
                </li>
                <li>
                  Facebook Insights（
                  <a
                    target='_blank'
                    rel='noreferrer noopener'
                    href='https://www.facebook.com/privacy/policy'
                  >
                    プライバシーポリシー
                  </a>
                  ）
                </li>
              </ol>
              当社は、当該分析サービスを統計分析に用い、個人情報を収集しないものとしますが、技術上の問題により、収集したデータに個人情報が含まれていたときは、当該個人情報を削除、置換、匿名化または統計処理の上、これを取り扱うものとします。
            </div>
            <h3>個人情報の取り扱い</h3>
            <div>
              当社は、個人情報保護法その他の法令および本指針の定めに基づき、お客様の個人情報を適正に取り扱います。なお第三者が運営するECサイト上で当社サービスをご利用される場合、当該ECサイトの個人情報保護指針の定めが適用される場合がございます。
            </div>
            <h3>個人情報の管理体制</h3>
            <div>
              当社は、個人情報の適正な管理のため、次に掲げる安全管理措置をとるものとします。
              <ol>
                <li>取扱責任者の任命、業務管理規定の策定その他の組織的な安全管理措置</li>
                <li>役員、従業員、派遣労働者に対する教育研修その他の人的な安全管理措置</li>
                <li>個人情報の保管場所の施錠および機器の管理その他の物理的な安全管理措置</li>
                <li>アクセス制御、セキュリティの確保その他の技術的な安全管理措置</li>
              </ol>
            </div>
            <h3>個人情報の正確性の確保</h3>
            <div>
              当社は、上記「個人情報の利用目的」のため必要な範囲内において、その保有する個人情報が正確かつ最新の内容であるように努めるものとします。
            </div>
            <h3>個人情報の第三者提供</h3>
            <div>
              当社は、次に掲げる場合に、上記「個人情報の利用目的」に掲げる範囲内において、個人情報または個人関連情報を第三者に対して提供することがあります。
              <ol>
                <li>試着アイテムのご購入のため当該アイテムに関する情報を提供する場合</li>
                <li>当社サービスを外部サーバーまたはクラウドサービス上で稼働させる場合</li>
                <li>当社サービスのため必要な情報を第三者から検査・照会等により取得する場合</li>
                <li>個人情報の管理業務について当社の監督の下、外部事業者に業務委託する場合</li>
                <li>監督機関または司法機関から法令に基づく要請を受けた場合</li>
              </ol>
            </div>
            <h3>個人情報の外国第三者提供</h3>
            <div>
              当社は、当社が保有する個人情報について、次に掲げる条件のいずれかが満たされる場合に、上記「個人情報の利用目的」および「個人情報の第三者提供」の範囲内において、外国にある第三者に提供することがあります。
              <ol>
                <li>
                  当該第三者が、我が国と同等の水準にあると認められる個人情報保護制度を有している国として個人情報の保護に関する法律施行規則で定める国にある場合
                </li>
                <li>
                  当該第三者が、個人情報取扱事業者が講ずべき措置に相当する措置を継続的に講ずるために必要な体制として個人情報の保護に関する法律施行規則で定める基準に適合する体制を整備している場合
                </li>
              </ol>
            </div>
            <h3>個人情報の廃棄</h3>
            <div>
              当社は、当社が保有する個人情報について、「個人情報の利用目的」が達成された場合には、法令により記録の保存が義務付けられている場合（個人情報保護法第29条第２項等）を除き、当該個人情報を復元不可能な方法により廃棄します。
            </div>
          </section>
          <section>
            <h2>3. 個人情報に関するお問い合わせ</h2>
            <h3>個人情報に関する開示請求・訂正請求</h3>
            <div>
              当社は、当社が保有する個人情報について、個人情報のご本人から、当社に対し、次に掲げる請求があった場合、ご請求に基づき、当該個人情報の開示または訂正をいたします。この場合、実費を手数料としてご請求する場合がございます。
              <ol>
                <li>本人の個人情報の開示請求</li>
                <li>本人の個人情報の訂正請求</li>
              </ol>
              ただし次に掲げる事由に該当する場合は、開示できない場合がございます。
              <ol>
                <li>本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                <li>個人情報保護法その他の法令に違反することとなる場合</li>
              </ol>
            </div>
            <h3>個人情報に関する利用停止・利用制限・廃棄請求</h3>
            <div>
              当社に対し、個人情報のご本人から、当社に対し、次に掲げるご請求があったときは、当社は速やかに必要な調査を行い、当該ご請求に理由があるときは、ご請求に基づき、当該個人情報の利用の停止、制限または廃棄その他の措置をいたします。
              <ol>
                <li>本人の個人情報の利用停止請求</li>
                <li>本人の個人情報の利用制限請求</li>
                <li>本人の個人情報の廃棄請求</li>
                <li>監督機関への申し立ての請求</li>
              </ol>
            </div>
          </section>
        </Container>
      </section>
    </>
  )
}
