// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WVHJ2ssatdRIrbBJW7ix{margin-bottom:64px}@media screen and (max-width: 850px){.WVHJ2ssatdRIrbBJW7ix{margin-bottom:32px}}.XGX0USHXr0GSJaBR9RnA{max-width:850px;margin:auto;padding:200px 32px 120px;text-align:center}.LWAzmPS2M32lZZMhdNFK{font-size:40px;margin-bottom:24px;text-align:center}@media screen and (max-width: 850px){.LWAzmPS2M32lZZMhdNFK{font-size:30px}}.J38ZuTeIJEvpeFN2A9Qt{text-align:center;margin-bottom:80px}", "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/index.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA,sBACE,kBAAA,CCkBA,qCDnBF,sBAGI,kBAAA,CAAA,CAEF,sBACE,eAAA,CACA,WAAA,CACA,wBAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CACA,kBAAA,CACA,iBAAA,CCKF,qCDRA,sBAKI,cAAA,CAAA,CAGJ,sBACE,iBAAA,CACA,kBAAA","sourcesContent":["@import './../../styles/variables';\r\n.page {\r\n  margin-bottom: 64px;\r\n  @include media-query('tb') {\r\n    margin-bottom: 32px;\r\n  }\r\n  &__container {\r\n    max-width: 850px;\r\n    margin: auto;\r\n    padding: 200px 32px 120px;\r\n    text-align: center;\r\n  }\r\n  &__title {\r\n    font-size: 40px;\r\n    margin-bottom: 24px;\r\n    text-align: center;\r\n    @include media-query('tb') {\r\n      font-size: 30px;\r\n    }\r\n  }\r\n  &__desc {\r\n    text-align: center;\r\n    margin-bottom: 80px;\r\n  }\r\n}\r\n","// color\r\n$theme-black: #333333;\r\n$theme-black-hover: #646464;\r\n$line-gray: #d9d9e2;\r\n$theme-gray: #f6f6f8;\r\n$contact-blue: #eef1f8;\r\n$required-red: #dd222b;\r\n\r\n// break-point\r\n$bp-sm: 550px;\r\n$bp-md: 850px;\r\n$bp-lg: 1100px;\r\n$bp-xl: 1440px;\r\n$breakpoint: (\r\n  sp: 'screen and (max-width: #{$bp-sm})',\r\n  tb: 'screen and (max-width: #{$bp-md})',\r\n  pc: 'screen and (max-width: #{$bp-lg})',\r\n  dt: 'screen and (max-width: #{$bp-xl})',\r\n);\r\n@mixin media-query($media) {\r\n  @media #{map-get($breakpoint, $media)} {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "WVHJ2ssatdRIrbBJW7ix",
	"page__container": "XGX0USHXr0GSJaBR9RnA",
	"page__title": "LWAzmPS2M32lZZMhdNFK",
	"page__desc": "J38ZuTeIJEvpeFN2A9Qt"
};
export default ___CSS_LOADER_EXPORT___;
