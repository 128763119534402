import React from 'react'
export const BG_PARTICLE3 = () => (
  <svg width='144' height='95' viewBox='0 0 144 95' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_f_748_2411)'>
      <path
        d='M56.5 3L3 44.5M56.5 3L101.5 22.5M56.5 3L40.5 92.5M3 44.5L40.5 92.5M3 44.5L141.5 64M3 44.5L101.5 22.5M40.5 92.5L141.5 64M40.5 92.5L101.5 22.5M141.5 64L101.5 22.5'
        stroke='#D9D9E2'
        strokeWidth='0.5'
      />
    </g>
    <defs>
      <filter
        id='filter0_f_748_2411'
        x='0.64856'
        y='0.711792'
        width='143.321'
        height='94.0743'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1' result='effect1_foregroundBlur_748_2411' />
      </filter>
    </defs>
  </svg>
)
