import React, { useContext } from 'react'
import styles from './index.module.scss'
// components
import { Container } from '../../components/common/Container'
import { TEXT_PLANPAGE } from '../../utils/constants'
import { AppContext } from '../../providers/AppContext'
import { markupLargeSpan } from '../../components/common/TextConverter'
import { getContactLink } from '../../utils/getLinks'
import { ColoredLinkButton } from '../../components/common/ColoredLinkButton'
import { BG_PARTICLE1 } from '../../images/bg_particle1'
import { BG_PARTICLE2 } from '../../images/bg_particle2'
import { BG_PARTICLE3 } from '../../images/bg_particle3'

export const PlanPage: React.FC = () => {
  const { lang } = useContext(AppContext)
  return (
    <>
      <section className={styles.plan}>
        <Container style={{ maxWidth: '850px', padding: '160px 32px 64px' }}>
          <div className={styles.plan__titleContainer}>
            <h1 className={styles.plan__title}>{TEXT_PLANPAGE.title[lang]}</h1>
            <div className={styles.plan__titleBG1}>
              <BG_PARTICLE1 />
            </div>
            <div className={styles.plan__titleBG2}>
              <BG_PARTICLE2 />
            </div>
            <div className={styles.plan__titleBG3}>
              <BG_PARTICLE3 />
            </div>
          </div>
          <div className={styles.plan__desc}>{TEXT_PLANPAGE.desc[lang]}</div>
          <div className={styles.plan__plans}>
            <div className={styles.plan__planItem}>
              <h2 className={styles.plan__planTitle}>{TEXT_PLANPAGE.plan1.title[lang]}</h2>
              <div className={styles.plan__planPrice}>
                {markupLargeSpan(TEXT_PLANPAGE.plan1.price[lang])}
              </div>
              <ul className={styles.plan__points}>
                {TEXT_PLANPAGE.plan1.points.map((item, index) => (
                  <li key={index} className={styles.plan__pointItem}>
                    {item[lang]}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.plan__planItem}>
              <h2 className={styles.plan__planTitle}>{TEXT_PLANPAGE.plan2.title[lang]}</h2>
              <div className={styles.plan__planDesc}>{TEXT_PLANPAGE.plan2.desc[lang]}</div>
            </div>
          </div>
          <div className={styles.plan__toContact}>
            <ColoredLinkButton
              text={TEXT_PLANPAGE.toContact[lang]}
              href={getContactLink(lang)}
              style={{ width: '240px', padding: '16px 40px' }}
            />
          </div>
        </Container>
      </section>
    </>
  )
}
