import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'

export const WavingTitle = ({ text, isWhiteText = false, isAuto = false }) => {
  const [animateIn, setAnimateIn] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const spanElm = useRef(null)

  useEffect(() => {
    if (isAuto) {
      window.addEventListener('load', () => {
        setTimeout(() => {
          startAnimation()
        }, 100)
      })
      setTimeout(() => {
        if (!animateIn) {
          startAnimation()
        }
      }, 500)
    }
  }, [])

  const checkScroll = () => {
    if (spanElm.current !== null) {
      const windowHeight = window.innerHeight
      const clientRect = spanElm.current.getBoundingClientRect()
      const elementTop = clientRect.top
      if (!animateIn && elementTop < windowHeight * 0.9) {
        startAnimation()
      }
    }
  }
  const startAnimation = () => {
    setAnimateIn(true)
    window.removeEventListener('scroll', checkScroll, false)
    setTimeout(() => {
      setAnimateOut(true)
    }, 500)
  }

  window.addEventListener('scroll', checkScroll)

  return (
    <p
      className={styles.colored_title}
      data-animate-in={animateIn}
      data-animate-out={animateOut}
      data-is-white-text={isWhiteText}
    >
      {text.map((itemX, indexX) => {
        if (typeof itemX === 'string') {
          return itemX.split('').map((itemY, indexY) => (
            <span
              key={`${indexX}-${indexY}`}
              style={{ transitionDelay: String(indexY * 0.03 + indexX * 0.03) + 's' }}
              className={styles.colored_title__text}
              ref={spanElm}
            >
              {itemY}
            </span>
          ))
        } else {
          return itemX
        }
      })}
    </p>
  )
}
