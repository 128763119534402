import React, { useRef } from 'react'
import { smoothScroll } from '../../../utils/functions'
import styles from './index.module.scss'

export const MainContactLink = ({ text, href, lang }) => {
  const btnElm = useRef(null)

  return (
    <a
      href={href}
      className={styles.button}
      data-lang={lang}
      onClick={() => {
        smoothScroll(href)
      }}
      ref={btnElm}
    >
      {text}
    </a>
  )
}
