import React, { useContext, useState } from 'react'
import { AppContext } from '../../../providers/AppContext'
import { getContactLink, getPlanLink, getTopLink } from '../../../utils/getLinks'
import { ColoredLinkButton } from '../../common/ColoredLinkButton'
import { LinkButton } from '../../common/LinkButton'
import styles from './index.module.scss'
import LOGO_IMG from '../../../images/logo/selfy_logo.png'
import { TEXTS_HEADER } from '../../../utils/constants'

export const HeaderMenuSP = () => {
  const { lang } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        className={styles.headerMenuBtn}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <span className={styles.headerMenuBtn__line} data-is-open={isOpen}></span>
      </div>
      <div className={styles.headerMenuSP} data-is-open={isOpen}>
        <div className={styles.headerMenuSP__logo}>
          <a href={getTopLink(lang)}>
            <img className={styles.header__logo} src={LOGO_IMG} alt='Selfy' />
          </a>
        </div>
        <div className={styles.headerMenuSP__links}>
          <a href={getTopLink(lang)} className={styles.headerMenuSP__link}>
            {TEXTS_HEADER.home[lang]}
          </a>
          <a href={getPlanLink(lang)} className={styles.headerMenuSP__link}>
            {TEXTS_HEADER.plan[lang]}
          </a>
          {/* <ColoredLinkButton
            text={TEXTS_HEADER.try_demo[lang]}
            href={getContactLink(lang)}
            style={{ width: '60vw', padding: '16px 0', marginBottom: '24px', marginTop: '40px' }}
          /> */}
          <LinkButton
            text={TEXTS_HEADER.contact[lang]}
            href={getContactLink(lang)}
            style={{ width: '60vw', padding: '16px 0' }}
          />
        </div>
      </div>
    </>
  )
}
