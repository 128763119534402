import React, { useContext } from 'react'
import styles from './index.module.scss'
import LOGO_IMG from '../../images/logo/selfy_logo.png'
import { TEXTS_FOOTER_LINKS } from '../../utils/constants'
import { AppContext } from '../../providers/AppContext'

export const Footer = ({}) => {
  const { lang, location } = useContext(AppContext)
  return (
    <footer
      className={styles.footer}
      data-is-top={location.path === '/' || location.path === `/${lang}`}
    >
      <div className={styles.footer__links}>
        {TEXTS_FOOTER_LINKS.map((item, index) => (
          <a
            className={styles.footer__link}
            href={item.href[lang]}
            target={item.openTab ? '_blank' : '_self'}
            key={index}
          >
            {item.title[lang]}
          </a>
        ))}
      </div>
      <img className={styles.footer__logo} src="https://storage.googleapis.com/prodpics-lp.appspot.com/logo/Logo_medium.png" alt='Prodpics' />
      <div className={styles.footer__copyright}>NectAI © 2022 All Rights Reserved.</div>
    </footer>
  )
}
