import React, { useContext } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { META_DATA } from '../../utils/constants'
import { AppContext } from '../../providers/AppContext'

export const Head = () => {
  const { lang } = useContext(AppContext)
  return (
    <HelmetProvider>
      <Helmet>
        <title>{META_DATA.title[lang]}</title>
        <meta name='description' content={META_DATA.description[lang]} />
        <meta property='og:url' content={META_DATA.url} />
        <meta property='og:title' content={META_DATA.title[lang]} />
        <meta property='og:site_name' content={META_DATA.title[lang]} />
        <meta property='og:description' content={META_DATA.description[lang]} />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={META_DATA.ogp.src} />
        <meta property='og:image:width' content={String(META_DATA.ogp.width)} />
        <meta property='og:image:height' content={String(META_DATA.ogp.height)} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={META_DATA.title[lang]} />
        <meta name='twitter:description' content={META_DATA.description[lang]} />
        <meta property='twitter:image' content={META_DATA.ogp.src} />
      </Helmet>
    </HelmetProvider>
  )
}
