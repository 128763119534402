import React, { useEffect, useState } from 'react'
import { TEXTS_CONTACT } from '../../../utils/constants'
import { validateEmail } from '../../../utils/functions'
import { markupBrAll } from '../../common/TextConverter'
import { Input } from '../Input'
import { Textarea } from '../Textarea'
import styles from './index.module.scss'
import { init, send } from 'emailjs-com'

export const ContactForm = ({ lang, onSinglePage = false }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [isReady, setIsReady] = useState(false)

  const userID = process.env.EMAILJS_PUBLIC_ID
  const serviceID = process.env.EMAILJS_SERVICE_ID
  const templateID = process.env.EMAILJS_TEMPLATE_ID
  const template_param = {
    to_name: name,
    to_email: email,
    company: company,
    message: message,
  }
  if (userID !== undefined && serviceID !== undefined && templateID !== undefined) {
    init(process.env.EMAILJS_PUBLIC_ID)
  }

  const handleForm = () => {
    send(serviceID, templateID, template_param).then(() => {
      setName('')
      setEmail('')
      setCompany('')
      setMessage('')
    })
    window.alert('Your message has been sent.\nお問い合わせを送信いたしました。')
  }
  useEffect(() => {
    if (name !== '' && validateEmail(email) && message !== '') {
      setIsReady(true)
    }
  }, [name, email, company, message])

  return (
    <form className={styles.contactform}>
      <Input
        input_id={TEXTS_CONTACT.contents[0].id}
        label={TEXTS_CONTACT.contents[0][lang]}
        required={TEXTS_CONTACT.contents[0].is_required}
        lang={lang}
        onSinglePage={onSinglePage}
        onChange={(text) => {
          setName(text)
        }}
      />
      <Input
        input_id={TEXTS_CONTACT.contents[2].id}
        label={TEXTS_CONTACT.contents[2][lang]}
        required={TEXTS_CONTACT.contents[2].is_required}
        lang={lang}
        onSinglePage={onSinglePage}
        onChange={(text) => {
          setCompany(text)
        }}
      />
      <Input
        input_id={TEXTS_CONTACT.contents[1].id}
        label={TEXTS_CONTACT.contents[1][lang]}
        required={TEXTS_CONTACT.contents[1].is_required}
        lang={lang}
        onSinglePage={onSinglePage}
        onChange={(text) => {
          setEmail(text)
        }}
      />

      <Textarea
        input_id={TEXTS_CONTACT.contents[3].id}
        label={TEXTS_CONTACT.contents[3][lang]}
        required={TEXTS_CONTACT.contents[3].is_required}
        lang={lang}
        onSinglePage={onSinglePage}
        onChange={(text) => {
          setMessage(text)
        }}
      />
      <div className={styles.contactform__caution}>{markupBrAll(TEXTS_CONTACT.caution[lang])}</div>
      <div className={styles.contactform__submit}>
        <input
          type='submit'
          value={TEXTS_CONTACT.submit_btn[lang]}
          className={styles.contactform__submit__btn}
          data-is-ready={isReady}
          data-single-page={onSinglePage}
          onClick={() => {
            handleForm()
          }}
        />
      </div>
    </form>
  )
}
