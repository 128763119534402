import React from 'react'
import styles from './index.module.scss'

export const LinkButton = ({ text, href, style = {}, onHeader = false, isBlack = false }) => {
  return (
    <a
      href={href}
      className={styles.button}
      style={style}
      data-on-header={onHeader}
      data-is-black={isBlack}
    >
      {text}
    </a>
  )
}
