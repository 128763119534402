// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zOAvseMxagKeQqSD3aZU{display:inline-block;text-align:center;padding:12px 40px;font-size:16px;font-weight:900;color:#fff;background:linear-gradient(60deg, #335fab 0%, #eb2d72 86.14%);border-radius:99px;cursor:pointer;transition:all .3s ease-out}@media screen and (max-width: 550px){.zOAvseMxagKeQqSD3aZU{padding:10px 40px;font-size:16px}}.zOAvseMxagKeQqSD3aZU:hover{color:rgba(255,255,255,.7)}", "",{"version":3,"sources":["webpack://./src/components/common/ColoredLinkButton/index.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA,sBACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,UAAA,CACA,6DAAA,CACA,kBAAA,CACA,cAAA,CACA,2BAAA,CCSA,qCDnBF,sBAYI,iBAAA,CACA,cAAA,CAAA,CAEF,4BACE,0BAAA","sourcesContent":["@import './../../../styles/variables';\r\n.button {\r\n  display: inline-block;\r\n  text-align: center;\r\n  padding: 12px 40px;\r\n  font-size: 16px;\r\n  font-weight: 900;\r\n  color: #fff;\r\n  background: linear-gradient(60deg, #335fab 0%, #eb2d72 86.14%);\r\n  border-radius: 99px;\r\n  cursor: pointer;\r\n  transition: all 0.3s ease-out;\r\n  @include media-query('sp') {\r\n    padding: 10px 40px;\r\n    font-size: 16px;\r\n  }\r\n  &:hover {\r\n    color: rgba($color: #fff, $alpha: 0.7);\r\n  }\r\n}\r\n","// color\r\n$theme-black: #333333;\r\n$theme-black-hover: #646464;\r\n$line-gray: #d9d9e2;\r\n$theme-gray: #f6f6f8;\r\n$contact-blue: #eef1f8;\r\n$required-red: #dd222b;\r\n\r\n// break-point\r\n$bp-sm: 550px;\r\n$bp-md: 850px;\r\n$bp-lg: 1100px;\r\n$bp-xl: 1440px;\r\n$breakpoint: (\r\n  sp: 'screen and (max-width: #{$bp-sm})',\r\n  tb: 'screen and (max-width: #{$bp-md})',\r\n  pc: 'screen and (max-width: #{$bp-lg})',\r\n  dt: 'screen and (max-width: #{$bp-xl})',\r\n);\r\n@mixin media-query($media) {\r\n  @media #{map-get($breakpoint, $media)} {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "zOAvseMxagKeQqSD3aZU"
};
export default ___CSS_LOADER_EXPORT___;
