import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import styles from './index.module.scss'
import { Head } from '../../components/Head'
import { AppProvider } from '../../providers/AppContext'

const Layout = () => {
  return (
    <AppProvider>
      <Head />
      <main className={styles.main}>
        <Header />
        <Outlet />
        <Footer />
      </main>
    </AppProvider>
  )
}

export default Layout
