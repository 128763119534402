import React, { useContext } from 'react'
import { TEXTS_HEADER } from '../../utils/constants'
import LOGO_IMG from '../../images/logo/selfy_logo.png'
import styles from './index.module.scss'
import { AppContext } from '../../providers/AppContext'
import { getContactLink, getPlanLink, getTopLink } from '../../utils/getLinks'
import { HeaderMenuSP } from './HeaderMenuSP'
import { LinkButton } from '../common/LinkButton'

export const Header = () => {
  const { lang, isScrolled, handleScrollFlg } = useContext(AppContext)

  window.addEventListener('scroll', function () {
    const scroll = window.pageYOffset
    if (scroll > 50) {
      handleScrollFlg(true)
    } else {
      handleScrollFlg(false)
    }
  })
  return (
    <header className={styles.header} data-scrolled={isScrolled}>
      <a href={getTopLink(lang)}>
        <img className={styles.header__logo} src="https://storage.googleapis.com/prodpics-lp.appspot.com/logo/Logo_small.png" alt='Prodpics' />
      </a>
      <div className={styles.header__menuPC}>
        {/* <a href={getPlanLink(lang)} className={styles.header__link}>
          {TEXTS_HEADER.plan[lang]}
        </a> */}
        {/* <LinkButton
          text={TEXTS_HEADER.try_demo[lang]}
          href={'/'}
          isBlack={true}
          style={{ marginRight: '16px' }}
        /> */}
        {/* <LinkButton text={TEXTS_HEADER.contact[lang]} href={getContactLink(lang)} /> */}
        <LinkButton text={TEXTS_HEADER.demo[lang]} href="https://app.prodpics.ai/" />
      </div>
      <HeaderMenuSP />
      {/* <div className={styles.header__header_buttons}>
      <TryOnButton text={TEXTS_HEADER.tryon[lang]} href={''} />
      {(media !== 'sp' && media !== 'tb') &&
      <ScrollButton text={TEXTS_HEADER.contact[lang]} href={'#contact'} />
      }
      </div> */}
    </header>
  )
}

/*
      {pathCount > 1 ? (
        <LinkButton text={TEXTS_HEADER.contact[lang]} href={`/${lang}#contact`} onHeader={true} />
      ) : (
        <ScrollButton text={TEXTS_HEADER.contact[lang]} href={'#contact'} />
      )}
*/
