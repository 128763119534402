import React, { useContext } from 'react'
import styles from './index.module.scss'
// components
import { Container } from '../../components/common/Container'
import { ContactForm } from '../../components/contact/ContactForm'
import { AppContext } from '../../providers/AppContext'
import { TEXT_CONTACTPAGE } from '../../utils/constants'
import { markupBrAll } from '../../components/common/TextConverter'
import { BG_PARTICLE1 } from '../../images/bg_particle1'
import { BG_PARTICLE2 } from '../../images/bg_particle2'
import { BG_PARTICLE3 } from '../../images/bg_particle3'

export const ContactPage: React.FC = () => {
  const { lang } = useContext(AppContext)
  return (
    <>
      <section className={styles.contact}>
        <Container style={{ maxWidth: '850px', padding: '160px 32px 64px' }}>
          <div className={styles.contact__titleContainer}>
            <h1 className={styles.contact__title}>{TEXT_CONTACTPAGE.title[lang]}</h1>
            <div className={styles.contact__titleBG1}>
              <BG_PARTICLE1 />
            </div>
            <div className={styles.contact__titleBG2}>
              <BG_PARTICLE2 />
            </div>
            <div className={styles.contact__titleBG3}>
              <BG_PARTICLE3 />
            </div>
          </div>
          <div className={styles.contact__desc}>{markupBrAll(TEXT_CONTACTPAGE.desc[lang])}</div>
          <ContactForm lang={lang} onSinglePage={true} />
        </Container>
      </section>
    </>
  )
}
