// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wuMPaf7Axlxwhg5Fwxrw{max-width:1184px;padding:0 32px;margin:auto}@media screen and (max-width: 550px){.wuMPaf7Axlxwhg5Fwxrw{padding:0 24px}}", "",{"version":3,"sources":["webpack://./src/components/common/Container/index.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACE,gBAAA,CACA,cAAA,CACA,WAAA,CCeA,qCDlBF,sBAKI,cAAA,CAAA","sourcesContent":["@import './../../../styles/variables';\r\n\r\n.container {\r\n  max-width: 1184px;\r\n  padding: 0 32px;\r\n  margin: auto;\r\n  @include media-query('sp') {\r\n    padding: 0 24px;\r\n  }\r\n}\r\n","// color\r\n$theme-black: #333333;\r\n$theme-black-hover: #646464;\r\n$line-gray: #d9d9e2;\r\n$theme-gray: #f6f6f8;\r\n$contact-blue: #eef1f8;\r\n$required-red: #dd222b;\r\n\r\n// break-point\r\n$bp-sm: 550px;\r\n$bp-md: 850px;\r\n$bp-lg: 1100px;\r\n$bp-xl: 1440px;\r\n$breakpoint: (\r\n  sp: 'screen and (max-width: #{$bp-sm})',\r\n  tb: 'screen and (max-width: #{$bp-md})',\r\n  pc: 'screen and (max-width: #{$bp-lg})',\r\n  dt: 'screen and (max-width: #{$bp-xl})',\r\n);\r\n@mixin media-query($media) {\r\n  @media #{map-get($breakpoint, $media)} {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wuMPaf7Axlxwhg5Fwxrw"
};
export default ___CSS_LOADER_EXPORT___;
